import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class EventWidgetService {
    constructor( @Inject(HttpClient) private http: HttpClient) {

    }

    get() {
        const remoteUrl = "/umbraco/api/EventApi/GetEventWidgetList";
        return this.http.post(remoteUrl, {}).toPromise();    
    }

}
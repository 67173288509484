import { Component } from '@angular/core';

@Component({
    selector: 'home-page-root',
    template: `<div class="row">
            <div class="col-lg-4">
                <h2 class="title mt-4">AHCC <span class="text-default">Insider</span></h2>
                <div class="separator-2"></div>
                <div class="block">
                    <article-widget-component newsletterName="AHCC Insider"></article-widget-component>
                </div>
            </div>
            <div class="col-lg-4">
                <h2 class="title mt-4">AHCC <span class="text-default">Blog</span></h2>
                <div class="separator-2"></div>
                <div class="block">
                    <article-widget-component newsletterName="AHCC Blog"></article-widget-component>
                </div>
            </div>
            <div class="col-lg-4">
                @{{ '{' }} Html.RenderPartial("Advertisement"); {{ '}' }}
                <h2 class="title mt-4"><span class="text-default">Events</span></h2>
                <div class="separator-2"></div>
                <event-widget-component></event-widget-component>
            </div>
        </div>`
})
export class HomePageRootComponent {
}
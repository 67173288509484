import { Component, Inject, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';

import { PublicationsService } from "./publications.service";
import publicationIssueTemplate from './publicationIssue.html'
import { IArticle, IIssue, INewsletterIssue } from './article.model';

@Component({
    selector: 'publication-issue-component',
    template: publicationIssueTemplate
})
export class PublicationIssueComponent implements OnInit {

    @Input() contentname: string;
    @Input() parentname: string;
    @Input() mediaattachmenturl: string;
    @Input() accesslevel: string;

    // current issue variables
    newsletterIssue: INewsletterIssue;
    issueDescription: string;
    articleList: IArticle[];
    newsletterName: string;
    year: string;
    issueUrl: string;
    canDownload: boolean;

    // pagination variables
    currentPage: number;
    pageSize: number;
    pageStart: number;
    pageEnd: number;
    sortBy: string;
    totalArticleCount: number;

    // year picker variables
    years: string[];
    issues: IIssue[];

    subscription: Subscription;

    constructor(@Inject(Location) private location: Location, @Inject(PublicationsService) private publicationsService: PublicationsService) {
    }

    ngOnInit() {
        let url = this.location.path();
        this.newsletterName = url.split('/')[1];
        this.issueUrl = (url.split('/')[2] ? url.split('/')[2] : null).split('?')[0];
        this.currentPage = 1;
        this.pageSize = 10;
        this.pageStart = 0;
        this.pageEnd = this.pageSize;

        this.subscription = this.publicationsService.articleListUpdated.subscribe((articleList) => {
            if (articleList) {
                this.articleList = articleList;
                this.totalArticleCount = articleList.length;
                this.currentPage = 1;
            }
        });

        this.LoadNewsletterIssue();
        this.publicationsService.LoadArticleList();
    }

    LoadNewsletterIssue() {
        this.publicationsService.SetNewsletterName(this.newsletterName);
        this.publicationsService.SetIssueName(this.issueUrl);

        this.canDownload = this.accesslevel == "Premium" || (this.accesslevel == "Basic" && this.parentname == "AHCC Quarterly Journal");

        this.publicationsService.GetNewsletterIssue().then(data => {
            this.newsletterIssue = data.IssueData;
            this.issueDescription = data.IssueData.IssueDescription;
            this.year = data.IssueData.Year;
            this.years = data.Years;
            this.yearChange();
        },
            (response => {
                console.log(response);
            })
        );
    }

    paginate(page) {
        this.currentPage = page;
        this.pageStart = (this.currentPage - 1) * this.pageSize;
        this.pageEnd = this.pageStart + this.pageSize;
    }

    sort(sortBy) {
        this.sortBy = sortBy;
    }

    yearChange() {
        this.publicationsService.GetIssuesForYear(this.year).then(data => {
            this.issues = data;
        });
    }

    issueChange(issue) {
        if (this.issueUrl != null) {
            location.href = '/' + this.newsletterName + '/' + this.issueUrl;
        }
    }



}
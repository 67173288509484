import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from "../shared/shared.module";

import { CEUTrackerAdminComponent } from "./ceuTrackerAdmin.component";
import { CEUTrackerAdminService } from "./ceuTrackerAdmin.service";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        FormsModule,
        HttpClientModule
    ],
    providers: [
        CEUTrackerAdminService
    ],
    declarations: [
        CEUTrackerAdminComponent
    ]
})
export class CeuTrackerAdminModule {
    ngDoBootstrap() {
    }
}


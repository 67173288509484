import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { EventsService } from "./events.service";
import { IEventTypeFilter } from './events.models';

@Component({
    selector: 'event-type-filter',
    template: `<div class="block clearfix" >` +
    `<h2 class="title">AHCC Events</h2>` +
    `<div class="separator-2"></div>` +
    `<nav><ul class="nav flex-column">` +
    `<li class="nav-item" *ngFor="let rt of this.EventTypeFilters">` +
    `<input type="checkbox" [(ngModel)]="rt.Selected" value="rt.EventType" (change)="filterChange(rt.EventType);" />&nbsp; &nbsp;{{rt.EventType}}` +
    `</li></ul></nav></div>`

})
export class EventTypeFilterComponent implements OnInit{
    
    EventTypeFilters: IEventTypeFilter[];

    constructor(@Inject(EventsService) private eventsService: EventsService, @Inject(Location) private location: Location) {
        
    }

    ngOnInit() {
        var url = this.location.path();
        var eventType = '';
        if (url.indexOf('?eventType=') > 0) {
            eventType = decodeURIComponent(url.substring(url.indexOf('?eventType=') + 11));
        }

        this.EventTypeFilters = [];

        this.eventsService.GetEventTypeList(eventType).then(data => {
            this.EventTypeFilters = data;
            this.eventsService.LoadEventList();
        });
    }

    filterChange(filterVal: string) {
        this.eventsService.SetEventTypeFilters(this.EventTypeFilters);
    }

}
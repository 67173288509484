import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { CredentialHoldersService } from "./credentialHolders.service";
import * as model from "./credentialHolderModels";
import { SortByPipe} from '../shared/sortBy.pipe';
import credentialHoldersTemplate from './credentialHolders.html';


@Component({
    selector: 'credential-holders-component',
    template: credentialHoldersTemplate,
})
export class CredentialHoldersComponent implements OnInit {

    @Input() pagesize: number;

    criteria: model.ISearchOptions;
    result: model.ICredentialHolder[];
    certifications: model.ICertification[];
    noResultsFound: boolean;
    showRequiredMsg: boolean;
    currentPage: number;
    totalCount: number;
    pageStart: number;
    pageEnd: number;
    sortType: number;
    sortOrder: string[];
    reverseSort: boolean;

    @ViewChild('credentialHoldersForm') credentialHoldersForm: HTMLFormElement;

    constructor(@Inject(CredentialHoldersService) private service: CredentialHoldersService, @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef) {   
    }

    ngOnInit() {
        
        this.certifications = this.service.GetCertifications();

        this.criteria = {
            FirstName: "",
            LastName: "",
            Certification: { CertTypeId: 0, Certification: "--Select--" },
            SortBy: model.SortTypes.LastName.valueOf()          
        };
        this.result = [];
        this.sortOrder = [];
        this.sortType = model.SortTypes.NoOrder.valueOf();
        this.reverseSort = false;
        this.noResultsFound = false;
        this.showRequiredMsg = false;
        this.currentPage = 1;
        this.totalCount = 0;
        if (this.pagesize == null && this.pagesize <= 0) {
            this.pagesize = 10;
        }
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

    clearForm(): void {
        console.debug("form cleared");

        this.criteria = {
            FirstName: "",
            LastName: "",
            Certification: { CertTypeId: 0, Certification: "--Select--" },
            SortBy: model.SortTypes.LastName.valueOf()
        };
        this.result = [];
        this.sortOrder = [];
        this.sortType = model.SortTypes.LastName.valueOf();
        this.totalCount = 0;
        this.noResultsFound = false;
    }

    search(): void {
        if (this.credentialHoldersForm.valid) {
            this.service.Search(this.criteria).then(response => {
                this.result = response;
                this.reverseSort = false;
                this.totalCount = this.result.length;
                this.noResultsFound = this.totalCount <= 0;
                this.pageStart = 0;
                this.pageEnd = this.pagesize;
                this.paginate(1);
            }).catch(response => {
                console.log(response);
            });
        }
        else {
            console.debug("form invalid");
        }
    }

    paginate(page: number): void {
        this.currentPage = page;
        this.pageStart = (this.currentPage - 1) * this.pagesize;
        this.pageEnd = parseInt(this.pageStart.toString()) + parseInt(this.pagesize.toString())
    }


    updateSort(sortBy: model.SortTypes): void {
        this.currentPage = 1;

        if (sortBy == this.sortType) {
            this.reverseSort = !this.reverseSort;
        }
        else {
            this.sortType = sortBy.valueOf();
            this.sortOrder = this.service.GetSortOrder(sortBy);
            this.reverseSort = false;
        }

    }

    isNameInvalid(): boolean {
        let invalid: boolean = false;

        if (this.credentialHoldersForm) {
            let lastName: any = this.criteria.LastName;
            let firstName: any = this.criteria.FirstName;
            invalid = lastName.length <= 0 && firstName.length <= 0;
        }
    
        return invalid;
    }

}

import { Component, Inject, OnInit } from '@angular/core';
import { PublicationsService } from "./publications.service";
import { IPublicationFilter } from './article.model';

@Component({
    selector: 'publication-filter-component',
    template: `<div class="block clearfix">` +
    `<h2 class="title">AHCC Publications</h2>` +
    `<div class="separator-2"></div>` +
    `<nav [hidden]="currentFilter"><ul class="nav flex-column">` +
    `<li class="nav-item" *ngFor="let p of publicationFilters">` +
    `<input type="checkbox" [(ngModel)]="p.Selected" value="p.Publication" (ngModelChange)="filterChange(p.Publication);" />&nbsp; &nbsp;{{p.Publication}}` +
    `</li></ul></nav></div>`
})
export class PublicationFilterComponent implements OnInit{
    
    publicationFilters: IPublicationFilter[];

    public onFilterChange:() => void;


    constructor(@Inject(PublicationsService) private publicationsService: PublicationsService) {
    }

    ngOnInit() {
        this.publicationFilters = [];
        this.publicationsService.GetPublicationList().then(data => {
            this.publicationFilters = data;
            this.publicationsService.LoadArticleList();
        },
            (response => {
                console.log(response);
            }
            )
        );
    }

    filterChange(filterVal: string) {
        this.publicationsService.SetPublicationFilters(this.publicationFilters);
        this.publicationsService.LoadArticleList();
        this.publicationsService.LoadBlogArticleList();
    }
}

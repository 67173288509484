import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, Subscription } from 'rxjs';

import { IResult } from './search.model';

export interface ISearchService {
    searchResultsUpdated: Subject<IResult[]>;
    RunSearch(searchTerm: string): void;
    SetSearchFilters(contentType: string, startDate: Date, sortBy: string, sortDirection: string): void;
    SetSortBy(sortBy: string, sortDirection: string): void;
}


@Injectable({
    providedIn: 'root'
})
export class SearchService implements ISearchService {

    constructor( @Inject(HttpClient) private http: HttpClient) {
    }

    searchResults: IResult[];

    searchTerm: string;
    contentType: string;
    startDate: Date;
    endDate: Date;
    sortBy: string;
    sortDirection: string;

    // Observable for when event list has been updated
    searchResultsUpdated = new Subject<IResult[]>();

    SetSearchFilters(contentType: string, startDate: Date, sortBy: string, sortDirection: string) {
        this.contentType = contentType;
        this.startDate = startDate;
        this.sortBy = sortBy;
        this.sortDirection = sortDirection;
        this.RunSearch(this.searchTerm);
    }

    RunSearch(searchTerm) {
        const remoteUrl = "/umbraco/api/SearchApi/RunSearch";

        this.searchTerm = searchTerm;
        var dateString = null;

        if (this.startDate) {
            dateString = this.startDate.toDateString();
        } 

        var searchCriteria = {
            searchTerm: this.searchTerm,
            contentType: this.contentType,
            startDate: dateString,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection
        }

        var headers = { "Content-Type": "application/json" };

        this.http.post<IResult[]>(remoteUrl, searchCriteria, { headers }).subscribe(data => {
            this.searchResultsUpdated.next(data);
        });
    }

    SetSortBy(sortBy: string, sortDirection: string) {
        this.sortBy = sortBy;
        this.sortDirection = sortDirection;
        this.RunSearch(this.searchTerm);
    }

}
import { Inject, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from "../shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { createCustomElement } from '@angular/elements';
import { UserImportReportComponent } from './userimportreport.component';
import { UserImportReportService } from './userimportreport.service';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        UserImportReportService
    ],
    declarations: [
        UserImportReportComponent
    ],
    entryComponents: [
        UserImportReportComponent
    ],
    bootstrap: [
        UserImportReportComponent
    ]
})
export class UserImportReportModule {
    constructor(@Inject(Injector) injector: Injector) {
        const userImportReportComponent = createCustomElement(UserImportReportComponent, { injector: injector });
        customElements.define('user-import-report-component', userImportReportComponent);
    }

    ngDoBootstrap() {
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from "../shared/shared.module";

import { ArticleListComponent } from "./articleList.component";
import { BlogComponent } from "./blog.component";
import { PublicationIssueComponent } from "./publicationIssue.component";
import { PublicationFilterComponent} from "./publicationFilter.component";
import { PublicationsService } from "./publications.service";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        SharedModule
    ],
    providers: [
        PublicationsService
    ],
    declarations: [
        ArticleListComponent,
        BlogComponent,
        PublicationIssueComponent,
        PublicationFilterComponent
    ]
})
export class PublicationsModule {

    ngDoBootstrap() {
    }
}

    

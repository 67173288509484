
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { EventsComponent } from "./events.component";
import { EventTypeFilterComponent } from "./eventTypeFilter.component";
import { EventsService } from "./events.service";
import { SharedModule } from "../shared/shared.module";


@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        HttpClientModule,
        FormsModule
    ],
    providers: [
        EventsService
    ],
    declarations: [
        EventsComponent,
        EventTypeFilterComponent
    ],
    bootstrap: [
        EventsComponent
    ]
})
export class EventsModule {

    ngDoBootstrap() {
        console.log('event boot');
    }
}
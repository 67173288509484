
import { Component, Inject, OnInit, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';

import { FocusAreaService } from "./focusArea.service";

import focusAreaFilterTemplate from "./focusAreaFilter.html";

@Component({
    selector: 'focus-area-filter',
    template: focusAreaFilterTemplate
})
export class FocusAreaFilterComponent implements OnInit{

    focusAreaList: any;
    currentFocusArea: string;

    constructor( @Inject(FocusAreaService) private focusAreaService: FocusAreaService, @Inject(Location) private location: Location) {
    }

  
    ngOnInit() {
        this.currentFocusArea = "";
        var fullUrl = this.location.path();

        if (fullUrl.indexOf('?focusArea=') > 0) {
            var focusArea = decodeURIComponent(fullUrl.substring(fullUrl.indexOf('?focusArea=') + 11));
            this.filterChange(focusArea);
        }

        this.focusAreaService.GetFocusAreaList().then(data => {
            this.focusAreaList = data;
        },
            (response => {
                console.log('Ctl', response);
            }
            )
        );
    }

    filterChange(focusArea: string) {
        this.currentFocusArea = focusArea;
        this.focusAreaService.SetFocusAreaFilter(focusArea);
    }

}
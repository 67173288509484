import { ChangeDetectorRef, Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';

import { Subscription } from 'rxjs';

import { SortByPipe } from '../shared/sortBy.pipe';

import { SearchService } from "./search.service";
import { IResult } from './search.model';
import searchTemplate from './search.html';

@Component({
    selector: 'search-component',
    template: searchTemplate
})
export class SearchComponent implements OnChanges, OnInit{

    searchResults: IResult[];

    subscription: Subscription;

    searchTerm: string;
    currentPage: number;
    pageSize: number;
    pageStart: number;
    pageEnd: number;
    numResults: number;

    SortBy: string;
    SortDirection: string;
    ContentType: string;
    DateRange: string;

    constructor( @Inject(Location) private location: Location, @Inject(SearchService) private searchService: SearchService,
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        console.log('search init', this.searchTerm);

        this.ContentType = '';
        this.DateRange = '';

        this.subscription = this.searchService.searchResultsUpdated.subscribe((searchResults) => {
            console.log('results updated', searchResults);
            if (searchResults) {
                this.searchResults = searchResults;
                this.numResults = searchResults.length;  
                this.currentPage = 1;
                this.pageSize = 10;
                this.pageStart = 0;
                this.pageEnd = this.pageSize;
                this.cdr.detectChanges();
            }
        });

        
        this.SortBy = 'Date';
        this.SortDirection = "Desc";

        var location = this.location.path();
        console.log('location', location);
        if (location.indexOf('?st=') > 0) {
            this.searchTerm = decodeURIComponent(location.substring(location.indexOf('?st=') + 4, location.indexOf('#') == -1 ? location.length : location.indexOf('#')));
            console.log('searchterm', this.searchTerm);
            this.searchService.RunSearch(this.searchTerm);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('changes', changes);
    }

    CriteriaChange(): void {
        this.currentPage = 1;

        var startDate = new Date();
        var endDate = new Date();

        if (this.DateRange == "Last 30 Days") {
            startDate.setDate(startDate.getDate() - 30);
        }

        if (this.DateRange == "Last 60 Days") {
            startDate.setDate(startDate.getDate() - 60);
        }

        if (this.DateRange == "Year to Date") {
            var year = endDate.getFullYear();
            startDate = new Date(year, 0, 1);
        }

        this.searchService.SetSearchFilters(this.ContentType, startDate, this.SortBy, this.SortDirection );
    }

    paginate(page: number): void {
        this.currentPage = page;
        this.pageStart = (this.currentPage - 1) * this.pageSize;
        this.pageEnd = this.pageStart + this.pageSize;
        this.cdr.detectChanges();
    }


    // Formats JSON dates correctly
    formatDate(dateVal: string, format): string {
        console.log('dateVal', dateVal);
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        if (format === "full") {
            return month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        } else {
            return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
    } 

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from "../shared/shared.module";

import { CredentialHoldersComponent } from "./credentialHolders.component";
import { CredentialHoldersService } from "./credentialHolders.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ],
    providers: [
        CredentialHoldersService
    ],
    declarations: [
        CredentialHoldersComponent
    ]
})
export class CredentialHoldersModule { }

import { Component, Inject, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { PublicationsService } from "./publications.service";
import { IArticle, IIssue } from './article.model';
import blogTemplate from './blog.html';

@Component({
    selector: 'blog-component',
    template: blogTemplate
})
export class BlogComponent implements OnInit {

    @Input() mediaattachment: string;
    @Input() contentname: string;

    //newsletter variables
    articleList: IArticle[];
    newsletterName: string;

    //pagination variables
    currentPage: number;
    pageSize: number;
    pageStart: number;
    pageEnd: number;
    sortBy: string;
    articleCount: number;

    subscription: Subscription;

    constructor(@Inject(PublicationsService) private publicationsService: PublicationsService, @Inject(Location) private location: Location) {
    }

    ngOnInit() {

        this.sortBy = "-PublicationDate";
        let url = this.location.path().split('?')[0]; //discard query string entries from the url
        this.newsletterName = url.split('/')[1];
        this.currentPage = 1;
        this.pageSize = 10;
        this.pageStart = 0;
        this.pageEnd = this.pageSize;

        this.subscription = this.publicationsService.blogListUpdated.subscribe((data) => {
            if (data) {
                this.articleList = data.Articles;
                this.articleCount = data.Articles.length;
                this.currentPage = 1;
            }
        });

        this.publicationsService.SetNewsletterName(this.newsletterName);
        this.publicationsService.LoadBlogArticleList();
    }


    paginate(page) {
        this.currentPage = page;
        this.pageStart = (this.currentPage - 1) * this.pageSize;
        this.pageEnd = this.pageStart + this.pageSize;
    }

    sort(sortBy) {
        this.currentPage = 1;

        if (this.sortBy == sortBy) {
            this.sortBy = "-" + this.sortBy;
        }
        else {
            this.sortBy = sortBy;
        }

        this.publicationsService.SetSortBy(this.sortBy);
    }

}
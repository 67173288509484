
import { Inject, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from "../shared/shared.module";
import { createCustomElement } from '@angular/elements';

import { PaymentComponent } from "./payment.component";

import { PaymentService } from "./payment.service";

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        HttpClientModule,
        FormsModule
    ],
    providers: [
        PaymentService
    ],
    declarations: [
        PaymentComponent
    ],
    entryComponents: [
        PaymentComponent
    ],
    bootstrap: [
        PaymentComponent
    ]
})
export class PaymentModule {
    constructor(@Inject(Injector) injector: Injector) {
        const paymentComponent = createCustomElement(PaymentComponent, { injector: injector });
        customElements.define('payment-component', paymentComponent);
    }

    ngDoBootstrap() {
    }
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import * as model from './credentialHolderModels';

export interface ICredentialHoldersService {
    GetCertifications(): model.ICertification[];
    Search(searchOptions: model.ISearchOptions): Promise<model.ICredentialHolder[]>;
    GetSortOrder(sortBy: model.SortTypes): string[];
}

@Injectable({
    providedIn: 'root'
})
export class CredentialHoldersService implements ICredentialHoldersService {

    constructor( @Inject(HttpClient) private http: HttpClient) {
    }

    GetCertifications(): model.ICertification[] {
        let list: model.ICertification[] = [
            { CertTypeId: 0, Certification: "--Select--" },
            { CertTypeId: 1, Certification: "HCS-D" },
            { CertTypeId: 2, Certification: "HCS-O" },
            { CertTypeId: 3, Certification: "HCS-H" },
            { CertTypeId: 4, Certification: "HCS-C" }
        ];
        return list;
    }

    Search(searchOptions: model.ISearchOptions): Promise<model.ICredentialHolder[]> {
        let lastName: string = searchOptions.LastName.trim();
        let firstName: string = searchOptions.FirstName.trim();

        return this.http.get<model.ICredentialHolder[]>("/umbraco/api/CertificationAndTrainingPagesApi/SearchCredentialHolders", {
            params: {
                lastName: lastName,
                firstName: firstName,
                certType: searchOptions.Certification.CertTypeId.toString(),
                sortOrder: searchOptions.SortBy.valueOf().toString()
            }
        }).toPromise();
    }

    GetSortOrder(sortBy: model.SortTypes): string[] {
        let sortOrder: string[] = [];

        if (sortBy == model.SortTypes.LastName) {
            sortOrder = [
                "LastName",
                "FirstName"
            ];
        }
        else if (sortBy == model.SortTypes.FirstName) {
            sortOrder = [
                "FirstName",
                "LastName"
            ];
        }

        return sortOrder;
    }
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, Subscription } from 'rxjs';

import { FocusAreaService } from '../shared/focusArea.service';
import {
    IAjaxResultBlogContent, IAjaxResultNewsletterIssueContent, IArticle, IArticleFilters,
    IPublicationFilter, IIssue, IIssueFilters, INewsletterYearFilters
} from './article.model';

export interface IPublicationsService {

    GetArticleList(): IArticle[];
    GetYears(): string[];

    LoadArticleList(): void;
    LoadBlogArticleList(): void;

    articleListUpdated: Subject<IArticle[]>;
    blogListUpdated: Subject<IAjaxResultBlogContent>;

    GetNewsletterIssue(): Promise<IAjaxResultNewsletterIssueContent>;
    GetPublicationList(): Promise<IPublicationFilter[]>;
    GetIssuesForYear(string): Promise<IIssue[]>;
    GetSelectedPublications(): string;

    SetNewsletterName(string): void;
    SetIssueName(string): void;
    SetPublicationFilters(publicationFilters: IPublicationFilter[]): void;
    SetFocusAreaFilter(string): void;
    SetSortBy(string): void;
}





@Injectable({
    providedIn: 'root'
})
export class PublicationsService implements IPublicationsService {

    constructor( @Inject(HttpClient) private http: HttpClient, @Inject(FocusAreaService) private focusAreaService: FocusAreaService) {
        this.subscription = this.focusAreaService.focusAreaUpdated.subscribe((focusArea) => {
            this.SetFocusAreaFilter(focusArea);
        });
    }

    newsletterIssue: IAjaxResultNewsletterIssueContent;
    newsletterName: string;
    issueName: string;
    articleList: IArticle[];
    years: string[];

    publicationFilters: IPublicationFilter[];
    focusAreaFilter: string;
    sortBy: string;
    articleFilters: IArticleFilters;
    issueFilters: IIssueFilters;
    
    newsletterYearFilters: INewsletterYearFilters;
    selectedPublicationsList: string;

    subscription: Subscription;

    // Observables for when article/blog list has been updated
    blogListUpdated = new Subject<IAjaxResultBlogContent>();

    articleListUpdated = new Subject<IArticle[]>();

    SetNewsletterName(newsletterName) {
        this.newsletterName = newsletterName;
    }

    SetIssueName(issueName) {
        this.issueName = issueName;
    }

    SetPublicationFilters(publicationFilters?: IPublicationFilter[]) {
        this.publicationFilters = publicationFilters;
    }

    SetFocusAreaFilter(focusArea: string) {
        this.focusAreaFilter = focusArea;
        this.LoadArticleList();
        this.LoadBlogArticleList();
    }

    SetSortBy(sortBy: string) {
        this.sortBy = sortBy;
        this.LoadArticleList();
        this.LoadBlogArticleList();
    }

    GetNewsletterIssue() {
        const remoteUrl = "/umbraco/api/PublicationApi/GetNewsletterIssue";

        this.issueFilters = {
            NewsletterName: this.newsletterName,
            IssueName: this.issueName
        }
        var headers = { "Content-Type": "application/json" };

        return this.http.post<IAjaxResultNewsletterIssueContent>(remoteUrl, this.issueFilters, { headers }).toPromise();
    }

    LoadArticleList() {
        var remoteUrl = "/umbraco/api/PublicationApi/GetArticleList";

        if (!this.newsletterName) {
            remoteUrl = "/umbraco/api/PublicationApi/GetAllArticlesList";
        }

        this.articleFilters = {
            PublicationFilters: this.publicationFilters,
            FocusAreaFilter: this.focusAreaFilter,
            NewsletterName: this.newsletterName,
            IssueName: this.issueName,
            SortBy: this.sortBy
        }

        var headers = { "Content-Type": "application/json" };

        this.http.post<IArticle[]>(remoteUrl, this.articleFilters, { headers }).subscribe(data => {
            this.articleList = data;
            this.articleListUpdated.next(data);
        });
    }

    LoadBlogArticleList() {
        const remoteUrl = "/umbraco/api/PublicationApi/GetBlogArticleList";

        let params = {
                publicationFilters: this.publicationFilters,
                focusAreaFilter: this.focusAreaFilter,
                newsletterName: this.newsletterName,
                issueName: this.issueName,
                sortBy: this.sortBy
        };

        let headers = new HttpHeaders({ "Content-Type": "application/json" });

        this.http.post<IAjaxResultBlogContent>(remoteUrl, JSON.stringify(params), { headers: headers }).subscribe(data => {
            this.articleList = data.Articles;
            this.blogListUpdated.next(data);
        });
    }


    GetPublicationList() {
        const remoteUrl = "/umbraco/api/PublicationApi/GetPublicationList";

        return this.http.get<IPublicationFilter[]>(remoteUrl, {}).toPromise().then(data => {
            this.publicationFilters = [];
            if (data) {
                
                for (var i = 0; i < data.length; i++) {
                    var thisVal = data[i];
                    this.publicationFilters.push(thisVal);
                }
                return this.publicationFilters;
            }
        });
    }

    GetIssuesForYear(year: string) {
        const remoteUrl = "/umbraco/api/PublicationApi/GetNewsletterIssuesForYear";

        this.newsletterYearFilters = {
            NewsletterName: this.newsletterName,
            Year: year
        };

        var headers = { "Content-Type": "application/json" };

        return this.http.post<IIssue[]>(remoteUrl, this.newsletterYearFilters, { headers }).toPromise();
    }

    GetArticleList() {
        return this.articleList;
    }

    GetYears() {
        return this.years;
    }

    GetSelectedPublications() {
        var selectedPublicationsList = 'Selected Publications: ';
        this.publicationFilters.forEach(function (filter) {
            if (filter.Selected == true) {
                selectedPublicationsList = selectedPublicationsList + filter.Publication + ', ';
            }
        });
        return selectedPublicationsList.substr(0, selectedPublicationsList.length - 2);
    }

}
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from "../shared/shared.module";


import { EventWidgetComponent } from "./eventWidget.component";
import { EventWidgetService } from "./event-widget.service";
import { ArticleWidgetComponent }  from "./articleWidget.component";
import { ArticleWidgetService } from "./article-widget.service";
import { HomePageRootComponent } from "./homePageRoot.component";

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
    ],
    providers: [
        EventWidgetService,
        ArticleWidgetService
    ],
    declarations: [
        EventWidgetComponent,
        ArticleWidgetComponent,
        HomePageRootComponent
    ]
})
export class HomePageModule {
    ngDoBootstrap() {
    }
}

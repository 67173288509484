import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { EventsService } from "./events.service";
import { IEvent } from './events.models';

import eventTemplate from './events.component.html';

@Component({
    selector: 'events-component',
    template: eventTemplate
})
export class EventsComponent implements OnInit {

    eventList: IEvent[] = [];
    selectedEvents: string = "";

    subscription: Subscription;

    currentPage: number;
    pageSize: number;
    pageStart: number;
    pageEnd: number;
    sortBy: string;
    numEvents: number;

    constructor(@Inject(EventsService) private eventsService: EventsService) {
    }

    ngOnInit() {
        this.currentPage = 1;
        this.pageSize = 10;
        this.sortBy = 'StartDate';

        this.subscription = this.eventsService.eventListUpdated.subscribe((eventList) => {
            if (eventList) {
                this.numEvents = eventList.length;
                this.displayEvents(eventList);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    displayEvents(eventList: IEvent[]) {
        this.currentPage = 1;
        this.pageStart = 0;
        this.pageEnd = this.pageSize;
        this.selectedEvents = this.eventsService.GetSelectedEvents();
        this.eventList = eventList;
    }

    paginate(pageObj: number) {
        this.currentPage = pageObj;
        this.pageStart = (this.currentPage - 1) * this.pageSize;
        this.pageEnd = this.pageStart + this.pageSize;
    }

    sort(sortBy) {
        this.currentPage = 1;

        if (this.sortBy == sortBy) {
            this.sortBy = "-" + this.sortBy;
        }
        else {
            this.sortBy = sortBy;
        }
    }

    // Formats JSON dates correctly
    formatDate(dateVal: string, format): string {
        console.log('dateVal', dateVal);
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        if (format === "full") {
            return month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        } else {
            return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
    }


    eventClick(titleUrl, pageUrl) {
        if (titleUrl) {
            window.open(titleUrl);
        }
        else {
            document.location.href = pageUrl;
        }
    }

}
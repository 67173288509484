import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FocusAreaFilterComponent } from "./focusAreaFilter.component";
import { FocusAreaService } from "./focusArea.service";
import { PagerComponent } from "./pager.component";
import { SortByPipe } from './sortBy.pipe';


@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    providers: [
        FocusAreaService
    ],
    declarations: [
        FocusAreaFilterComponent,
        PagerComponent,
        SortByPipe
    ],
    exports: [
        PagerComponent,
        FocusAreaFilterComponent,
        SortByPipe
    ]
})
export class SharedModule { }

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import * as model from './ceuTrackersAdminModels';

export interface ICEUTrackerAdminService {
    GetAllUsers(sortBy: string, searchText: string, pageNumber: number, pageSize: number): Promise<model.IUserDetails[]>;
}

@Injectable({
    providedIn: 'root'
})
export class CEUTrackerAdminService implements ICEUTrackerAdminService {

    sortBy: string;

    constructor( @Inject(HttpClient) private http: HttpClient) {
    }

    GetAllUsers(sortBy, searchText, pageNumber, pageSize): Promise<model.IUserDetails[]>{
        return this.http.get<model.IUserDetails[]>("umbraco/api/CEUTrackerAdminApi/GetAllUsers", {
            params: {
                sortBy: sortBy,
                searchText: searchText,
                pageNumber: pageNumber.toString(),
                pageSize: pageSize.toString()
            }
        }).toPromise();
    }
}
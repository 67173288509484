import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

    transform(value: any[], order = '', column: any[]): any[] {
        if (!value || order === '' || !order) {
             return value;
        } // no array
        if (value.length <= 1) {
            return value;
        } // array with only one item
        if (!column || column.length == 0) {
            if (order === 'asc') {
                return value.sort()
            }
            else {
                console.log('reverse');
                return value.sort().reverse();
            }
        } // sort 1d array
        var sortedVal = orderBy(value, column, [order]);
        return sortedVal;
    }
}
import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { ArticleWidgetService } from './article-widget.service';
import widgetTemplate from "/assets/js/angular/homePage/article-widget.html"


@Component({
    selector: 'article-widget-component',
    template: widgetTemplate
})
export class ArticleWidgetComponent implements OnInit {

    articleList: any;
    @Input() newslettername: string;

    constructor( @Inject(ArticleWidgetService) private articleWidgetService: ArticleWidgetService) {
    }

    ngOnInit() {
        this.articleWidgetService.get(this.newslettername).then(data => {
            this.articleList = data;
        })
        
    }

}

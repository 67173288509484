import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FocusAreaService {

    constructor( @Inject(HttpClient) private http: HttpClient) {
    }

    // Observable for when event list has been updated
    focusAreaUpdated = new Subject<string>();
    focusArea$ = this.focusAreaUpdated.asObservable();

    GetFocusAreaList() {
        const remoteUrl = "/umbraco/api/ResourceApi/GetFocusAreaList";

        return this.http.get<string[]>(remoteUrl).toPromise().then(
            (response) => {
                return response;
            },
            response => {
                console.error(response);
            });
    }

    SetFocusAreaFilter(focusArea: string) {
        this.focusAreaUpdated.next(focusArea);
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from "../shared/shared.module";

import { SearchComponent } from "./search.component";
import { SearchService } from "./search.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ],
    providers: [
        SearchService
    ],
    declarations: [
        SearchComponent
    ]
})
export class SearchModule { }


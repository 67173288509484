///// <reference path="../ceutracker/ceutracker.component.ts" />
import {Component, Inject, Input, OnInit } from '@angular/core';
import { CEUTrackerAdminService } from "./ceuTrackerAdmin.service";
import ceuTrackerAdminTemplate from './ceuTrackerAdmin.html';
import * as model from "./ceuTrackersAdminModels";

@Component({
    selector: 'ceu-tracker-admin',
    template: ceuTrackerAdminTemplate,
})
export class CEUTrackerAdminComponent {

    users: model.IUserDetails[];

    currentPage: number;
    pageSize: number;
    sortBy: string;
    searchText: string;
    totalUsersCount: number;

    constructor(@Inject(CEUTrackerAdminService) private service: CEUTrackerAdminService) {
        
    }

    ngOnInit() {
        this.sortBy = 'FirstName';
        this.currentPage = 1;
        this.pageSize = 50;
        this.searchText = '';
        this.getUsers();
    }

    getUsers() {
        this.service.GetAllUsers(this.sortBy, this.searchText, this.currentPage, this.pageSize).then(response => {
            this.users = response;
            this.totalUsersCount = this.users && this.users.length > 0 ? this.users[0].UsersTotal : 0;
        })
    }

    search() {
        this.getUsers();
    }

    paginate(pageObj) {
        this.currentPage = pageObj;
        this.getUsers();
    }

    sort(sortBy) {
        this.currentPage = 1;

        if (this.sortBy == sortBy) {
            this.sortBy = this.sortBy + ' DESC';
        }
        else {
            this.sortBy = sortBy;
        }
        this.getUsers();
    }
}
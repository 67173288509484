import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PublicationsService } from "./publications.service";
import * as models from "./article.model";
import articleListTemplate from './articleList.html';


@Component({
    selector: 'article-list-component',
    template: articleListTemplate
})
export class ArticleListComponent implements OnInit {

    articleList: models.IArticle[];

    selectedPublications: string;

    articleCount: number;
    currentPage: number;
    pageSize: number;
    pageStart: number;
    pageEnd: number;
    sortBy: string;

    subscription: Subscription;

    constructor(@Inject(PublicationsService) private publicationsService: PublicationsService) {

    }

    ngOnInit() {
        this.sortBy = "-PublicationDate";
        this.currentPage = 1;
        this.pageSize = 10;

        this.subscription = this.publicationsService.articleListUpdated.subscribe((articleList) => {

            if (articleList) {
                this.articleList = articleList;
                this.articleCount = articleList.length;
                this.currentPage = 1;
                this.pageStart = 0;
                this.pageEnd = this.pageSize;
            }
        });
    }

    paginate(page) {
        this.currentPage = page;
        this.pageStart = (this.currentPage - 1) * this.pageSize;
        this.pageEnd = this.pageStart + this.pageSize;
    }

    sort(sortBy) {
        this.currentPage = 1;
        if (this.sortBy == sortBy) {
            this.sortBy = "-" + this.sortBy;
        }
        else {
            this.sortBy = sortBy;
        }

        this.publicationsService.SetSortBy(this.sortBy);
    }

}
import { Component, OnInit, Inject } from "@angular/core";
import { NgbDateAdapter, NgbDateParserFormatter, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { CustomDateAdapter, CustomDateParserFormatter } from "../shared/ngbDateParsers";

import userImportReportTemplate from './userimportreport.component.html';
import { UserImportReport } from "./userimportreport.model";
import { UserImportReportService } from "./userimportreport.service";
@Component({
    selector: 'user-import-report-component',
    template: userImportReportTemplate,
    providers: [
        { provide: NgbDateAdapter, useClass: CustomDateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class UserImportReportComponent implements OnInit {
    startDate: Date;
    endDate: Date;
    users: UserImportReport[];

    constructor(@Inject(UserImportReportService) private service: UserImportReportService, @Inject(NgbCalendar) private calendar: NgbCalendar) {
        this.endDate = null;
        this.startDate = null;
        this.users = null;
    }

    ngOnInit() {
        this.reset();
    }

    getReportData() {
        this.service.getReportData(this.startDate, this.endDate).then(data => {
            this.users = data;
        });
    }

    reset() {
        this.endDate = null;
        this.startDate = null;
        this.users = null;
    }

    export() {
        const sd = this.startDate != null
            ? (this.startDate.getFullYear().toString() + '-' + (this.startDate.getMonth() + 1).toString() + '-' + this.startDate.getDate().toString())
            : null;
        const ed = this.endDate != null
            ? (this.endDate.getFullYear().toString() + '-' + (this.endDate.getMonth() + 1).toString() + '-' + this.endDate.getDate().toString())
            : null;
        document.location.href = '/umbraco/Surface/Export/UserImportCSV?startDate=' + sd + '&endDate=' + ed;
    }
}
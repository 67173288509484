import { Inject, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from "../shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { createCustomElement } from '@angular/elements';
import { PassFailReportComponent } from './passfailreport.component';
import { PassFailReportService } from './passfailreport.service';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
        NgbModule
    ],
    providers: [
        PassFailReportService
    ],
    declarations: [
        PassFailReportComponent
    ],
    entryComponents: [
        PassFailReportComponent
    ],
    bootstrap: [
        PassFailReportComponent
    ]
})
export class PassFailReportModule {
    constructor(@Inject(Injector) injector: Injector) {
        const passFailReportComponent = createCustomElement(PassFailReportComponent, { injector: injector });
        customElements.define('pass-fail-report-component', passFailReportComponent);
    }

    ngDoBootstrap() {
    }
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ArticleWidgetService {
    constructor( @Inject(HttpClient) private http: HttpClient) {

    }

    get(newsletterName: string) {
        const remoteUrl = "/umbraco/api/PublicationApi/GetArticleWidgetList";

        return this.http.post(remoteUrl, { newsletterName: newsletterName }).toPromise();
    }

}
export enum SortTypes {
    NoOrder = 0,
    FirstName,
    LastName,
    Certification
}

export interface ISearchOptions {
    LastName: string;
    FirstName: string;
    Certification: ICertification;
    SortBy: SortTypes;
}

export interface ICredentialHolder {
    CandidateId: string;
    FirstName: string;
    LastName: string;
    Certifications: ICertification[];
}

export interface ICertification {
    CertTypeId: number;
    Certification: string;
}

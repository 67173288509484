import { Component, Inject, Input, OnInit } from '@angular/core';
import { EventWidgetService } from "./event-widget.service";
import widgetTemplate from "/assets/js/angular/homePage/event-widget.html"


@Component({
    selector: 'event-widget-component',
    template: widgetTemplate
})
export class EventWidgetComponent implements OnInit {

    EventList: any;

    constructor(@Inject(EventWidgetService) private eventWidgetService: EventWidgetService) {

    }

    ngOnInit() {
        this.eventWidgetService.get().then(data => {
            this.EventList = data;
        })
    }

    
    // Formats JSON dates correctly
    formatDate(dateVal: string, format): string {
        const month: string[] = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        const d = new Date(parseInt(dateVal.replace(/\/Date\((.*?)\)\//gi, "$1"), 10));

        if (format === "full") {
            return month[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        } else {
            return (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        }
    }

}


import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import pagerTemplate from "./pager.html";

@Component({
    selector: 'pager',
    template: pagerTemplate
})
export class PagerComponent implements OnInit {

    @Input() className: string;
    @Input() page: number;
    @Input() pageSize: number;
    @Input() totalRowCount: number;

    @Output() onPageChange = new EventEmitter<number>();

    startPage: number;
    pageCount: number;
    pages: any[];

    constructor() {
    }

    ngOnInit() {
        this.recalculate();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.recalculate();
    }

    changePage(page: number): void {
        this.onPageChange.emit(page);
        window.scrollTo(0, 0);
    }

    recalculate(): void {
        // Don't know why this is necessary, but it was being treated as a string
        this.pageSize = parseInt(this.pageSize.toString());

        // [pageCount] calculation formula below makes it "overflow" to the next page
        // and then taking the Math.floor() of it.
        const pageCount = Math.floor((this.totalRowCount + this.pageSize - 1) / this.pageSize);

        let startPage = Math.max(this.page - 5, 1);
        let endPage = startPage + 9;

        if (endPage > pageCount) {
            endPage = pageCount;

            startPage = endPage < 11
                ? 1
                : endPage - 9;
        }

        const displayCount = endPage - startPage + 1;
        const pages = [];

        for (let i = 0; i < displayCount; i++)
            pages.push(startPage + i);

        this.startPage = startPage;
        this.pageCount = pageCount;
        this.pages = pages;
    }
}
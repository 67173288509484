import { Injectable, Inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { FocusAreaService } from '../shared/focusArea.service';
import { IEvent, IEventTypeFilter, IAjaxResultEventListContent } from './events.models';



@Injectable({
    providedIn: 'root'
})
export class EventsService {

    constructor( @Inject(HttpClient) private http: HttpClient, @Inject(FocusAreaService) private focusAreaService: FocusAreaService) {
        this.subscription = this.focusAreaService.focusAreaUpdated.subscribe((focusArea) => {
            this.SetFocusAreaFilter(focusArea);
        });
    }

    EventTypeFilters: IEventTypeFilter[];
    sortBy: string;
    focusAreaFilter: string;
    SelectedEventsList: string;

    subscription: Subscription;

    // Observable for when event list has been updated
    eventListUpdated = new Subject<IEvent[]>();

    // Sets which filters are selected and calls for event list to be reloaded accordingly
    SetEventTypeFilters(EventTypeFilters?: IEventTypeFilter[]) {
        this.EventTypeFilters = EventTypeFilters;
        this.LoadEventList();
    }

    LoadEventList() {
        const remoteUrl = "/umbraco/api/EventApi/GetEventList";

        var eventFilters = {
            EventTypeFilters: this.EventTypeFilters,
            focusAreaFilter: this.focusAreaFilter,
            SortBy: this.sortBy
        }

        var headers = { "Content-Type": "application/json" };

        this.http.post<IEvent[]>(remoteUrl, eventFilters, { headers }).subscribe(data => {
            this.eventListUpdated.next(data);
        });
    }

    // Returns the list of event types and selects where approrpriate
    GetEventTypeList(selectedFilter) {
        const remoteUrl = "/umbraco/api/EventApi/GetEventTypeList";

        return this.http.get<IEventTypeFilter[]>(remoteUrl, {}).toPromise().then(data => {
            this.EventTypeFilters = [];

            if (data) {
                for (var i = 0; i < data.length; i++) {
                    var thisVal = data[i];

                    if (thisVal.EventType == selectedFilter) {
                        thisVal.Selected = true;
                    }
                    this.EventTypeFilters.push(thisVal);
                }

                return this.EventTypeFilters;
            };
        });
    }

    // Gets the selected event types in readable string
    GetSelectedEvents() {
        var selectedEventsList = 'Selected Events: ';
        this.EventTypeFilters.forEach(function (filter) {
            if (filter.Selected == true) {
                selectedEventsList = selectedEventsList + filter.EventType + ', ';
            }
        });
        
        return selectedEventsList.substr(0, selectedEventsList.length - 2);
    }

    SetFocusAreaFilter(focusArea: string) {
        this.focusAreaFilter = focusArea;
        this.LoadEventList();
    }

    SetSortBy(sortBy: string) {
        this.sortBy = sortBy;
        this.LoadEventList();
    }

    SetEventType(EventType: string) {
        for (var i = 0; i < this.EventTypeFilters.length; i++) {
            if (this.EventTypeFilters[i].EventType == EventType) {
                this.EventTypeFilters[i].Selected = true;
            }
            else {
                this.EventTypeFilters[i].Selected = false;
            }
        }
        this.LoadEventList();
    }

}
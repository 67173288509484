import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PassFailReport } from "./passfailreport.model";

@Injectable({
    providedIn: 'root'
})
export class PassFailReportService {
    constructor(@Inject(HttpClient) private http: HttpClient) {
    }

    getReportData(startDate: Date | null, endDate: Date | null): Promise<PassFailReport[]> {
        const url: string = '/umbraco/api/ReportApi/PassFailReport';
        var params = {
            startDate: (startDate != null ? startDate.toJSON() : null),
            endDate: (endDate != null ? endDate.toJSON() : null)
        };
        return this.http.get<PassFailReport[]>(url, { params: params }).toPromise();
    }
}